import React from 'react'
import { Form } from 'react-bootstrap'

const CheckboxInput = ({ name, label, value, checkboxChanged }) => {
  return (
    <Form.Check
      type="checkbox"
      label={label}
      checked={value ? value : false}
      onChange={() => checkboxChanged(name)}
    />
  )
}

export default CheckboxInput
