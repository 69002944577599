import React, { useEffect, useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
  position: 'relative'
};

const remove ={
  position: 'absolute',
  top: -12,
  right: -12,
  backgroundColor: 'black',
  color: 'white',
  padding: 5,
  zIndex: 40,
  width: 24,
  height: 24,
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  cursor: 'pointer'
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

function DragDrop({ name, fileChanged, options = {}, valid, id, url, defaultFiles = [], defaultChanged }) {
  const [files, setFiles] = useState([]);
  const [_defaultFiles, setDefaultFiles] = useState([]);
  const { getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      if(options.maxFiles && options.maxFiles == 1){
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })))
      }
      else {
        setFiles(currentData =>[
          ...currentData,
          ...acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))]);
        
        
      }
      fileChanged(acceptedFiles, name)
      
    },
    maxFiles: options.maxFiles ? options.maxFiles : 0
  });
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  const handleRemoveFile = (file) => {
    setFiles(currentData => {
      if(Array.isArray(currentData)){
        return currentData.filter(item => item.name !== file.name)
      }
      else {
        return null
      }
    })
    fileChanged(file, name, true)
  }

  const handleRemoveDefault = (id, file) => {
    options.customRemoveImgFromArray(id, file._id).then(res => {
      defaultChanged(file, name, true)
    })
  }

  const thumbs = files.map((file,index) => (
    <div style={thumb} key={file.name+index} >
      <div style={remove} onClick={()=>handleRemoveFile(file)}>x</div>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt="thumbnail"
        />
      </div>
    </div>
  ));

  const thumbsDefault = _defaultFiles.map((file, index) => (
    <div style={thumb} key={file.name + index} >
      { options.customRemoveImgFromArray &&
        <div style={remove} onClick={()=>handleRemoveDefault(id, file)}>x</div>}
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt="thumbnail"
        />
      </div>
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  useEffect(() => {
    let imgurl = process.env.REACT_APP_API_URL + url + '/' + id + '/' + name +'/'
    if (defaultFiles.length !== 0) {
      if(Array.isArray(defaultFiles)){
        let imagesArray = []
        for(const [index,_image] of defaultFiles.entries()) {
          let imgObj = {
            _id: _image._id,
            name: _image.img, 
            preview: imgurl + _image._id,
          }
          imagesArray.push(imgObj)
        }
        setDefaultFiles(imagesArray)
      }
      else {
        setDefaultFiles([{
          name: defaultFiles,
          preview: imgurl,
        }]);
      }
    }

  }, [defaultFiles])

  // useEffect(() => {
  //   console.log(_defaultFiles, files)
  // }, [_defaultFiles, files])

  return (
    <section className={`container ${valid === undefined || valid ? '' : 'invalid-field'}`}>
      <div {...getRootProps({ className: 'dropzone', style })}>
        <input {...getInputProps()} />
        <p>Arraste e solte alguns arquivos aqui ou clique para selecionar arquivos</p>
      </div>
      <aside style={thumbsContainer}>
        {thumbs}
        {!(options.maxFiles && options.maxFiles == 1 && files.length > 0) && thumbsDefault}
      </aside>
    </section>
  );
}

export default DragDrop
