//Arquivo para configurar todas as rotas de request do módulo

//Importa a biblioteca Axios com um interceptor customizado
import axios from './client'
import { serialize } from '@equipedigitalfloripa/ed-cms/api'

//Url base do módulo no back-end
const url = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : 'http://localhost:3000/'
const full_url = url + 'juridicos/'

//AVISO IMPORTANTE!
//É necessário incluir os credentials em cada request para que o express possa
//adicionar os cookies necessários para uso 
const options = {
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
}
//Configuração adicional para upload de servicos
const optionsUpload = {
  withCredentials: true
}


//Pega todos os items
const fetchItems = (query) => {
  let url = full_url
  if (query) url += ('?' + serialize(query))
  return axios.get(url, options)
}

//Pega um Único item
const fetchItem = (id) => axios.get(full_url + id, options)

//Adiciona item
const addItem = (juridico) => axios.post(full_url, juridico, options)

//Edita um item
const editItem = (id, juridico) => axios.patch(full_url + id, juridico, options)

//Remove um item
const removeItem = (id) => axios.delete(full_url + id, options)

//Atualizar status de um item
const statusItem = (id, status) => axios.patch(full_url + id + '/status?status=' + status, {}, options)

//Faz o upload de imagem (única)
const uploadJuridico = (id, formData) => axios.post(full_url + id + '/imagem', formData, optionsUpload)



export {
  fetchItems,
  fetchItem,
  addItem,
  editItem,
  removeItem,
  statusItem,
  uploadJuridico
}