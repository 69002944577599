import React from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { MdContactMail } from "react-icons/md";
import { BigCard, Tabela } from "@equipedigitalfloripa/ed-cms/components";
import { Header } from "@equipedigitalfloripa/ed-cms/components";
import * as contatosApi from "../api/contatos.js";
import moment from "moment";

export const Contatos = () => {
  const translations = useSelector((state) => state.translations.translations);
  const contatoTranslations = translations["system-contatos"];
  const columns = [
    {
      title: contatoTranslations["edit-name"],
      field: "name",
      render: (rowData) => (
        <span style={{ width: 80, fontSize: 14 }}> {rowData.name}</span>
      ),
      cellStyle: {
        maxWidth: 80,
      },
    },
    {
      title: contatoTranslations["edit-email"],
      field: "email",
      render: (rowData) => (
        <span style={{ width: 80, fontSize: 14 }}> {rowData.email}</span>
      ),
    },
    {
      title: contatoTranslations["edit-data"],
      field: "createdAt",
      render: (rowData) => (
        <span style={{ width: 80, fontSize: 14 }}>
          {moment(rowData.createdAt).format("DD/MM/YYYY H:mm")}
        </span>
      ),
    },
    {
      title: contatoTranslations["edit-subject"],
      field: "subject",
      render: (rowData) => (
        <span style={{ width: 80, fontSize: 14 }}> {rowData.subject}</span>
      ),
    },
    {
      title: contatoTranslations["edit-setor"],
      field: "setor",
      cellStyle: {
        maxWidth: 80,
      },
      render: (rowData) => (
        <span style={{ width: 80, fontSize: 14 }}> {rowData.setor}</span>
      ),
    },
  ];

  return (
    <>
      <Header
        title={contatoTranslations.title}
        subtitle={contatoTranslations.subtitle}
      >
        <MdContactMail />
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={contatosApi.fetchItems}
            removeFunction={contatosApi.removeItem}
            title={contatoTranslations.title}
            disableAdd
            disableStatus
            enableDatas
            defaultSort={"createdAt"}
            url={"contatos"}
          />
        </BigCard>
      </Container>
    </>
  );
};
