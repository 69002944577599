import React from 'react'
import { Form } from 'react-bootstrap'

const UrlInput = ({ name, title, value, options = {},fieldChanged, friendlyUrlChanged, valid, type = 'text' }) => {
  return (
    <Form.Control
      type={type}
      placeholder={title}
      value={value ? value : ''}
      onChange={e => fieldChanged(name, e.target.value)}
      onBlur={e => friendlyUrlChanged(name, e.target.value || null)}
      isInvalid={valid === undefined ? false : !valid}
      isValid={valid === undefined ? false : valid}
      autoComplete="one-time-code"
    />
  )
}

export default UrlInput
