import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import { MainLayout } from "@equipedigitalfloripa/ed-cms/layouts";

import IndexPage from "../pages/Index";
import NotFoundPage from "../pages/NotFound";

import ContentsPage from "../pages/Contents";
import EditContentPage from "../pages/EditContent";

import NoticiasPage from "../pages/Noticias";
import EditNoticiasPage from "../pages/EditNoticia";

import EditImagemPrinc from "../pages/EditImagemPrinc";


import JuridicosPage from "../pages/Juridicos";
import EditJuridicosPage from "../pages/EditJuridicos";

import ArquivosPage from "../pages/Arquivos";
import EditArquivosPage from "../pages/EditArquivo";

// import ServicosPage from '../pages/Servicos'
// import EditServicosPage from '../pages/EditServicos'

import SliderPage from "../pages/Slider";
import EditSliderPage from "../pages/EditSlider";

import CategoriasPage from "../pages/Categorias";
import EditCategoriasPage from "../pages/EditCategorias";

// import VideosPage from '../pages/Videos'
// import EditVideosPage from '../pages/EditVideos'
import CategoriasJuridicoPage from "../pages/CategoriasJuridico";
import EditCategoriasJuridicoPage from "../pages/EditCategoriasJuridico";

import PagesPage from "../pages/Pages";
import EditPagesPage from "../pages/EditPages";

import ConveniosPage from "../pages/Convenios";
import EditConveniosPage from "../pages/EditConvenio";

import ConveniosCategoriaPage from "../pages/ConveniosCategoria";

import EditConvenioCategoriaPage from "../pages/EditConvenioCategoria";

import MemorialPage from "../pages/Memorial";
import EditMemorialPage from "../pages/EditMemorial";

import {
  UsersPage,
  EditUserPage,
  EditMetatagsPage,
  EditConfigPage,
  EditSocialMidiaPage,
  TranslationsPage,
  EditTranslationsPage,
  ViewContatosPage,
} from "@equipedigitalfloripa/ed-cms/pages";

import {
  startSetLang,
  startFetchTranslations,
} from "@equipedigitalfloripa/ed-cms/actions";

import { useSelector } from "react-redux";

import "@equipedigitalfloripa/ed-cms/layouts/main.css";
import "@equipedigitalfloripa/ed-cms/pages/main.css";
import "@equipedigitalfloripa/ed-cms/components/main.css";
import EditServicosConf from "../pages/EditServicosConf";
import { Contatos } from "../pages/Contatos";

const SystemRouter = () => {
  const dispatch = useDispatch();
  const url =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_URL
      : "http://localhost:4000/";
  if (url == undefined) url = "http://localhost:4000/";

  console.log(url)
  const full_url = url + "metatags/";
  const full_url_config = url + "config/";
  const full_url_socialmidia = url + "socialmidia/";
  const { loggedIn, user } = useSelector((state) => state.auth);
  const translations = useSelector(
    (state) => state.translations.translations.sidebar
  );

  useEffect(() => {
    if (user && user.preferences) {
      dispatch(startSetLang(user.preferences.lang));
      dispatch(startFetchTranslations(process.env.REACT_APP_API_URL));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const navigation = [
    {
      title: translations.site_news,
      link: [
        {
          link: "/noticias",
          title: translations.site_news,
        },
        {
          link: "/categorias",
          title: translations.site_categories,
        },
      ],
    },
    {
      title: translations.site_juridic,
      link: [
        {
          link: "/juridicos",
          title: translations.site_juridic,
        },
        {
          link: "/categorias_juridico",
          title: translations.site_categoriesJuridic,
        },
      ],
    },
    {
      title: "Configuraçoes gerais",
      link: [
        {
          link: "/imagem",
          title: translations.imagem
            ? translations.imagem
            : "Imagem Principal WHATSAPP",
        },
        {
          link: '/imagem',
          title: "Alterar imagem popup"
        },
        {
          link: "/config_services",
          title: translations.config_services
            ? translations.config_services
            : "Editar area servicos",
        },
      ],
    },
    {
      link: "/memorial",
      title: 'Memorial',
    },
    {
      link: "/pages",
      title: translations.site_pages,
    },
    {
      link: "/contents",
      title: translations.site_simple_content,
    },
    {
      link: "/arquivos",
      title: translations.site_files,
    },
    // {
    //   link: '/servicos',
    //   title: translations.serviços
    // },
    {
      link: "/slider",
      title: translations.slider,
    },
    {
      title: "Convenios",
      link: [
        {
          link: "/convenios",
          title: translations.site_convenios,
        },
        {
          link: "/convenio_categoria",
          title: translations.convenio_categoria
            ? translations.convenio_categoria
            : "Lista Categorias",
        },
      ],
    },
    // {
    //   link: '/videos',
    //   title: translations.site_videos
    // }
  ];
  return loggedIn ? (
    <Router>
      <MainLayout navigation={navigation}>
        <Switch>
          <Route path="/" component={IndexPage} exact />

          <Route
            path="/edit/pages/:id"
            render={(props) => <EditPagesPage {...props} edit={true} />}
          />
          <Route path="/add/pages" component={EditPagesPage} />
          <Route path="/pages" component={PagesPage} />

          <Route path="/imagem" component={EditImagemPrinc} />
          <Route path="/config_services" component={EditServicosConf} />

          <Route
            path="/edit/noticias/:id"
            render={(props) => <EditNoticiasPage {...props} edit={true} />}
          />
          <Route path="/add/noticias" component={EditNoticiasPage} />
          <Route path="/noticias" component={NoticiasPage} />

          <Route
            path="/edit/juridicos/:id"
            render={(props) => <EditJuridicosPage {...props} edit={true} />}
          />
          <Route path="/add/juridicos" component={EditJuridicosPage} />
          <Route path="/juridicos" component={JuridicosPage} />

          <Route
            path="/edit/contents/:id"
            render={(props) => <EditContentPage {...props} edit={true} />}
          />
          <Route path="/add/contents" component={EditContentPage} />
          <Route path="/contents" component={ContentsPage} />

          <Route
            path="/edit/arquivos/:id"
            render={(props) => <EditArquivosPage {...props} edit={true} />}
          />
          <Route path="/add/arquivos" component={EditArquivosPage} />
          <Route path="/arquivos" component={ArquivosPage} />

          {/* <Route
            path="/edit/servicos/:id"
            render={(props) => (
              <EditServicosPage {...props} edit={true} />
            )}
          />
          <Route path="/add/servicos" component={EditServicosPage} />
          <Route path="/servicos" component={ServicosPage} /> */}

          <Route
            path="/edit/slider/:id"
            render={(props) => <EditSliderPage {...props} edit={true} />}
          />
          <Route path="/add/slider" component={EditSliderPage} />
          <Route path="/slider" component={SliderPage} />

          <Route
            path="/edit/categorias/:id"
            render={(props) => <EditCategoriasPage {...props} edit={true} />}
          />
          <Route path="/add/categorias" component={EditCategoriasPage} />
          <Route path="/categorias" component={CategoriasPage} />
          <Route
            path="/edit/categorias_juridico/:id"
            render={(props) => (
              <EditCategoriasJuridicoPage {...props} edit={true} />
            )}
          />
          <Route
            path="/add/categorias_juridico"
            component={EditCategoriasJuridicoPage}
          />
          <Route
            path="/categorias_juridico"
            component={CategoriasJuridicoPage}
          />

          <Route path="/add/convenios" component={EditConveniosPage} />
          <Route path="/convenios" component={ConveniosPage} />
          <Route path="/edit/convenios/:id">
            <EditConveniosPage api_url={url} edit={true} />
          </Route>

          <Route
            path="/convenio_categoria"
            component={ConveniosCategoriaPage}
          />
          <Route
            path="/add/convenio_categoria"
            component={EditConvenioCategoriaPage}
          />
          <Route
            path="/edit/convenio_categoria/:id"
            render={(props) => (
              <EditConvenioCategoriaPage {...props} edit={true} />
            )}
          />

          <Route
            path="/edit/memorial/:id"
            render={(props) => (
              <EditMemorialPage {...props} edit={true} />
            )}
          />
          <Route path="/add/memorial" component={EditMemorialPage} />
          <Route path="/memorial" component={MemorialPage} />

          <Route path="/edit/metatags">
            <EditMetatagsPage full_url={full_url} />
          </Route>
          <Route path="/edit/config">
            <EditConfigPage full_url={full_url_config} />
          </Route>
          <Route path="/edit/socialmidia">
            <EditSocialMidiaPage full_url={full_url_socialmidia} />
          </Route>

          <Route path="/edit/users/:id">
            <EditUserPage api_url={url} edit={true} />
          </Route>
          <Route path="/add/users">
            <EditUserPage api_url={url} />
          </Route>
          <Route path="/users">
            <UsersPage api_url={url} />
          </Route>

          <Route path="/edit/contatos/:id">
            <ViewContatosPage api_url={url} edit={true} />
          </Route>
          <Route path="/contatos">
            <Contatos api_url={url} />
          </Route>

          <Route path="/edit/translations/:id">
            <EditTranslationsPage api_url={url} />
          </Route>
          <Route path="/translations">
            <TranslationsPage api_url={url} />
          </Route>
          <Route component={NotFoundPage} />
        </Switch>
      </MainLayout>
    </Router>
  ) : (
    <Redirect to="/login" />
  );
};

export default SystemRouter;
