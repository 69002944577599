import React from 'react'
import { useSelector } from 'react-redux'
import { Modal as BsModal, Button } from 'react-bootstrap'
import { MdCheck, MdClose } from 'react-icons/md';
import { BsExclamation } from 'react-icons/bs'
import "./modal.scss"

export const Modal = ({ title, body, show, handleClose, type, confirmFunction, cancelFunction }) => {
  const allTranslations = useSelector(state => state.translations.translations)
  const translations = allTranslations['modal']
  return (
    <BsModal
      show={show}
      onHide={handleClose}
      centered
      className={`custom_modal ${type ? type : 'success'}`}
    >
      <BsModal.Header>
        {type == 'warning' ?
          <BsExclamation size="2rem" /> :
          type == 'error' ?
          <MdClose size="2rem" /> :
          <MdCheck size="2rem" />
        }
        <h1>{title ? title : translations[type ? type : 'warning']}</h1>
      </BsModal.Header>
      <BsModal.Body>
        <p>{body ? body : translations[type ? type : 'warning']}</p>
        <hr />
        <div className={"custom_modal__options"}>
          {confirmFunction &&
            <>
              <Button onClick={confirmFunction}>
                {translations['ok']}
              </Button>
              <div className="line_divisoria" ></div>
            </>
          }
          <Button onClick={() => {
            handleClose()
            if (cancelFunction) cancelFunction()
          }
          } className={confirmFunction ? 'gray' : ''}>
            {translations[!confirmFunction ? 'ok' : 'cancel']}
          </Button>
        </div>

      </BsModal.Body>
    </BsModal>
  )
}
