import React from 'react';
import ReactDOM from 'react-dom';
import './styles/styles.scss'
import App from './App';

console.log(process.env.REACT_APP_API_URL)

ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById('root')
);
