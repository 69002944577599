import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Col, Container, Form, Row, Dropdown } from "react-bootstrap";
import ReactLoading from "react-loading";
import { BigCard } from "../BigCard";
import { Header } from "../Header";
import { Modal } from "../Modal";
import FormField from "../FormHelper/FormField";
import TextInput from "../FormHelper/TextInput";
import UrlInput from "../FormHelper/UrlInput";
import CheckboxInput from "../FormHelper/CheckboxInput";
import FileInput from "../FormHelper/FileInput";
import MultiSelect from "../FormHelper/MultiSelect";
import Select from "../FormHelper/Select";
import DragDrop from "../FormHelper/DragDrop";
import Date from "../FormHelper/Date";
import accents from "friendly-url";

import "./editpage.scss";

const EditPage = ({
  title,
  subtitle,
  selectedData,
  fetchFunction,
  addFunction,
  editFunction,
  uploadFunction,
  getUrlNoticia,
  url,
  edit,
}) => {
  const translations = useSelector(
    (state) => state.translations.translations.formhelper
  );
  //History para navegação de página
  let history = useHistory();

  //Todos os tipos de campos enviaveis
  const submitable = [
    "text",
    "checkbox",
    "select",
    "multi",
    "tiny",
    "date",
    "friendly-url",
  ];

  //Principal objeto de dados dos campos
  const [data, setData] = useState({});

  //Está enviando os dados ao backend
  const [loading, setLoading] = useState(false);

  //Status de cada campo
  //usado para definir se um campo precisa de validação, se já foi validado e etc.
  const [fieldStatus, setFieldStatus] = useState({});

  //Estado para informar se há arquivo a ser enviado
  const [hasFile, setHasFile] = useState([]);

  //Estado de arquivo para ser enviado
  const [file, setFile] = useState({});

  const [defaultFiles, setDefaultFiles] = useState({});

  //Mensagem de erro
  const [errorMsg, setErrorMsg] = useState("");

  //Refs
  const fieldRefs = useRef([]);
  fieldRefs.current = [];

  // Tratadores de modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Puxa id de item da URL
  const { id, id2 } = useParams();

  //Inicialização da página de edição
  useEffect(() => {
    //Roda apenas se página está em modo de edição
    if (edit) {
      //Puxa infos do objeto da API a partir do ID na url
      fetchFunction(id2 || id)
        .then((response) => {
          let allData = response.data;
          console.log(allData);
          let filteredData = {};
          let statusData = {};
          //Seleciona apenas os dados que foram setados na configuração desta página
          //e salva no estado "data"
          selectedData.forEach((value) => {
            if (!allData[value.name]) return;
            filteredData[value.name] =
              value.options && value.options.populated
                ? allData[value.name]._id
                : allData[value.name];

            if (value.name === "_id") return;
            //Define estado de arquivo
            statusData[value.name] = {};
            statusData[value.name].title = value.title;
            if (value.type === "file" || value.type === "dragdrop") {
              setHasFile((currentData) => {
                let returnObj = currentData;
                returnObj.push(value.name);

                return [...returnObj];
              });
              if (!allData[value.name]) return;
              setDefaultFiles((currentData) => {
                currentData[value.name] = allData[value.name];
                return Object.assign({}, currentData);
              });
            }
            if (value.options) {
              statusData[value.name].optional = true;
              statusData[value.name].usingOptional = value.name in allData;
            }
            if (value.validation && value.validation.required) {
              statusData[value.name].validation = true;
            }
          });

          setFieldStatus(statusData);
          setData(filteredData);
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 404) {
            history.replace("/404");
          }
        });
    } else {
      let statusData = {};
      selectedData.forEach((value) => {
        if (value.name === "_id") return;
        //Seta estado de arquivo
        statusData[value.name] = {};
        statusData[value.name].title = value.title;
        if (value.type === "file" || value.type === "dragdrop") {
          setHasFile((currentData) => {
            if (!currentData.includes(value.name)) currentData.push(value.name);
            return currentData;
          });
        }
        if (value.options && value.options.optional) {
          // handleAddOptional(value)
          statusData[value.name].optional = true;
          statusData[value.name].usingOptional = false;
        }
        if (value.validation && value.validation.required) {
          statusData[value.name].validation = true;
        }
      });
      setFieldStatus(statusData);
    }
  }, []);

  const addToRefs = (el, index) => {
    if (el && !fieldRefs.current.includes(el)) {
      fieldRefs.current[index] = el;
    }
  };

  const handleAddWidget = (value) => {
    setFieldStatus((current) => {
      let newData = Object.assign({}, current);
      newData[value].usingOptional = true;
      return newData;
    });
    setTimeout(() => {
      const index = selectedData.findIndex((item) => item.name === value);
      fieldRefs.current[index].scrollIntoView();
      fieldRefs.current[index].classList.add("added");
    }, 100);
  };

  //Tratador de campo modificado genérico
  const fieldChanged = (fieldId, value, maxLength = false) => {
    const item = selectedData.find((_data) => _data.name === fieldId);
    //Define o data com novo valor
    const newValue = maxLength ? value.slice(0, maxLength) : value;

    if (value === "") {
      if ("validation" in item) {
        if (item.validation.required && !(item.validation.password && edit)) {
          handleValidate(fieldId, false);
        } else {
          handleValidate(fieldId, true);
        }
      }
      setData((currentData) => {
        delete currentData[fieldId];
        return currentData;
      });
    } else {
      if (item.type !== "friendly-url") {
        handleValidate(fieldId, true);
      }

      // create friendly-url dynamic
      if (item.name === "titulo") {
        // all to lower case regex spaces - special characters
        let formatValue = newValue
          .toLowerCase()
          .replace(/\s/g, "_")
          .replace(/^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/, "");

        //format new value
        setData((currentData) => {
          currentData["url"] = formatValue;
          return currentData;
        });
      }
      setData((currentData) => {
        currentData[fieldId] = newValue;
        return currentData;
      });
    }

    //Força rerenderização da página para atualizar valores nos campos
    setData((currentData) => {
      return Object.assign({}, currentData);
    });
  };

  //Tratador de campo modificado checkbox
  const checkboxChanged = (fieldId) => {
    setData((currentData) => {
      currentData[fieldId] = !currentData[fieldId];
      return currentData;
    });
    setData((currentData) => {
      return Object.assign({}, currentData);
    });
  };

  //Tratador de campo modificado multi select
  const multiChanged = (e, fieldId) => {
    setData((currentData) => {
      //Converte objeto de seleção do multi select para
      //um array compativel com bancos de dados
      let newMulti = [];
      e.forEach((item) => {
        newMulti.push(item.value);
      });

      if (newMulti.length > 0) {
        handleValidate(fieldId, true);
        currentData[fieldId] = newMulti;
      } else {
        if (fieldId in currentData) delete currentData[fieldId];
        handleValidate(fieldId, false);
      }
      return currentData;
    });
    setData((currentData) => {
      return Object.assign({}, currentData);
    });
  };

  const fileChanged = (e, fieldId, remove = false) => {
    handleValidate(fieldId, true);
    //Tratador de 'file'
    if ("target" in e) {
      setFile((currentData) => {
        currentData[fieldId] = [e.target.files[0]];
        return currentData;
      });
      setData((currentData) => {
        currentData[fieldId] = e.target.files[0].name;
        return currentData;
      });
    }
    //Tratador de 'dragdrop
    else {
      setFile((currentData) => {
        if (!remove) {
          if (typeof currentData[fieldId] === "undefined") {
            currentData[fieldId] = [];
          }
          currentData[fieldId] = [...currentData[fieldId], ...e];
          return currentData;
        } else {
          if (
            currentData[fieldId] &&
            Array.isArray(currentData[fieldId]) &&
            currentData[fieldId].length > 0
          ) {
            currentData[fieldId] = currentData[fieldId].filter(
              (item) => item.name !== e.name
            );
            return currentData;
          }
        }
      });
      setData((currentData) => {
        if (!remove) {
          if (Array.isArray(e) && e.length > 0) {
            if (typeof currentData[fieldId] === "undefined") {
              currentData[fieldId] = [];
            }
            let names = e.map((item) => ({ img: item.name, _id: item.name }));
            currentData[fieldId] = [...currentData[fieldId], ...names];
            return currentData;
          }
        } else {
          if (currentData[fieldId]) {
            currentData[fieldId] = currentData[fieldId].filter(
              (item) => item.img !== e.name
            );
            return currentData;
          }
        }
      });
    }
  };

  const defaultChanged = (e, fieldId, remove = false) => {
    //Tratador de 'dragdrop
    setDefaultFiles((currentData) => {
      if (!currentData) return;
      if (!remove) {
        if (!currentData[fieldId]) currentData[fieldId] = [];
        currentData[fieldId] = [...currentData[fieldId], ...e];
        return currentData;
      } else {
        if (currentData[fieldId] && currentData[fieldId].length > 0) {
          currentData[fieldId] = currentData[fieldId].filter(
            (item) => item._id !== e._id
          );
          return Object.assign({}, currentData);
        }
      }
    });
    setData((currentData) => {
      if (!remove) {
        if (Array.isArray(e) && e.length > 0) {
          let names = e.map((item) => ({ img: item.name, _id: item.name }));
          currentData[fieldId] = [...currentData[fieldId], ...names];
          return currentData;
        }
      } else {
        if (currentData[fieldId] && currentData[fieldId].length > 0) {
          currentData[fieldId] = currentData[fieldId].filter(
            (item) => item._id !== e._id
          );
          return currentData;
        }
      }
    });
  };

  const onFileUpload = async (itemId) => {
    // return
    for (const name of hasFile) {
      if (typeof file[name] !== "undefined") {
        const formData = new FormData();
        // Update the formData object
        for (const item of file[name]) {
          formData.append(name, item, item.name);
        }
        // Request made to the backend api
        // Send formData object
        uploadFunction(itemId, formData);
      }
    }
  };

  const handleValidate = (fieldId, value, message = false) => {
    setFieldStatus((currentData) => {
      if (!currentData) return;

      let newData = Object.assign({}, currentData);
      if (!newData[fieldId]) {
        let obj = selectedData.find((item) => item.name == fieldId);
        newData[fieldId] = {
          title: obj.title,
        };
      }

      if (message) {
        newData[fieldId].message = message;
      }

      newData[fieldId].valid = value;

      if (value) {
        delete newData[fieldId].message;
      }

      return newData;
    });
  };

  const checkUrl = async (value) => {
    let search = "?url=" + value;
    let result = await getUrlNoticia(search);
    console.log(result);
    return result;
  };
  // trata url amigavel verifica caso tenha não deixa finalizar o cadastro
  const friendlyUrlChanged = async (fieldId, value, maxLength = false) => {
    const item = selectedData.find((_data) => _data.name === fieldId);
    //Define o data com novo valor
    const newValue = maxLength ? value.slice(0, maxLength) : value;
    //Verifica se url amigavel já existe
    let result = await checkUrl(value);

    if (result.data.haveUrlAlready) {
      handleValidate(fieldId, false, " already exist.");
      setData((currentData) => {
        currentData[fieldId] = "";
        return currentData;
      });
    } else {
      handleValidate(fieldId, true);
      setData((currentData) => {
        currentData[fieldId] = newValue.toLowerCase();
        // .replace(/\s/g, "_");
        // .replace(/^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/, "");
        /** substituindo os acentos */
        currentData[fieldId] = accents.remove(currentData[fieldId]);
        return currentData;
      });
    }

    //Força rerenderização da página para atualizar valores nos campos
    setData((currentData) => {
      return Object.assign({}, currentData);
    });
  };

  //Envia os dados finais para o backend
  const submitData = (event) => {
    event.preventDefault();
    event.stopPropagation();
    //Identifica se os dados estão validos
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   setValidated(true)
    //   return
    // }
    //Objeto de dados a serem enviados
    let sendData = {};
    let validData = true;

    //Seleciona apenas os enviaveis descritos pela array "submitable"
    selectedData.forEach(async (item) => {
      if (
        submitable.includes(item.type) &&
        item.name in data &&
        data[item.name] !== ""
      ) {
        sendData[item.name] = data[item.name];
        handleValidate(item.name, true);
      }
      // else if((item.type === 'friendly-url') && item.name in data && data[item.name] !== ''){
      //   let result = await checkUrl(data[item.name])
      //   console.log(result)
      //   if(result.data.haveUrlAlready){
      //     handleValidate(item.name, false)
      //     validData = false
      //   }
      // }
      else if (
        (item.type === "dragdrop" || item.type === "file") &&
        item.name in data &&
        data[item.name] !== ""
      ) {
        handleValidate(item.name, true);
      } else if (item.validation && item.validation.required) {
        if (!(item.validation.password && edit)) {
          handleValidate(item.name, false);
          validData = false;
        }
      }
    });

    if (!validData) {
      setErrorMsg(translations.please_required);
      handleShow();
      return;
    }

    // return
    //Tratador de edição de item
    if (edit) {
      setLoading(true);
      editFunction(data._id, sendData)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            console.log(hasFile, hasFile.length, file);
            if (hasFile && hasFile.length > 0 && file) {
              onFileUpload(data._id)
                .then((res) => {
                  history.push("/config_services");
                  setLoading(false);
                })
                .catch((e) => {
                  setLoading(false);
                });
            } else {
              setLoading(false);
              history.push("/config_services");
            }
          } else {
            setLoading(false);
            throw new Error("error submitting");
          }
        })
        .catch((e) => {
          errorHandler(e);
        });
    }
    //Tratador de adição de item
    else {
      addFunction(sendData)
        .then((res) => {
          if (res.status === 201) {
            if (hasFile && hasFile.length > 0 && file) {
              onFileUpload(res.data._id, "")
                .then((res) => {
                  history.push("/" + url);
                  setLoading(false);
                })
                .catch((e) => {
                  setLoading(false);
                });
            } else {
              setLoading(false);
              history.push("/" + url);
            }
          } else {
            setLoading(false);
            errorHandler({});
          }
        })
        .catch((e) => {
          errorHandler(e);
        });
    }
  };

  const errorHandler = (e) => {
    setLoading(false);
    if (!e.response) {
      setErrorMsg("");
      handleShow();
    } else if (e.response.status === 409) {
      console.log(e.response.data.messages);

      const name = e.response.data.fields[0];
      handleValidate(name, false, " " + translations.inuse);
      setErrorMsg(translations.fields_in_use);
      handleShow();
    } else if (e.response.status === 424) {
      console.log(e.response.data.messages);

      const names = e.response.data.fields;
      names.forEach((name) => {
        handleValidate(name, false, " " + translations.isrequired);
      });
      setErrorMsg(translations.please_required);
      handleShow();
    } else if (e.response.status === 428) {
      console.log(e.response.data.messages);

      const names = e.response.data.fields;
      names.forEach((name) => {
        handleValidate(name, false, " " + translations.minlen);
      });
      setErrorMsg(translations.minimum_length);
      handleShow();
    } else {
      setErrorMsg("");
      handleShow();
    }
  };
  return (
    <React.Fragment>
      <Header title={title} subtitle={subtitle} back={"/" + url}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          {
            //Não renderiza página até finalizar a chamada de objetos do backend em caso de edição.
            //Evita que campos como TinyMCE e Multiselect sejam inicializados sem valores iniciais
            edit && !data._id ? (
              <div
                style={{
                  width: "4em",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ReactLoading color="#0586D7" type="spin" />
              </div>
            ) : (
              <Form noValidate onSubmit={submitData} autoComplete="off">
                {selectedData.map(
                  (
                    {
                      name,
                      title,
                      type,
                      subtitle = "",
                      options = {},
                      validation = {},
                      conditional = null,
                      selectOptions = [],
                      tooltip = false,
                    },
                    index
                  ) => {
                    //Check se campo vai ser visualizado

                    //Checkar se as condicões de visualizações nas opções são atendidas
                    if (conditional && conditional.length > 0) {
                      let isVisible = true;
                      conditional.forEach((condition) => {
                        if (
                          "equal" in condition &&
                          data[condition.name] !== condition.equal
                        ) {
                          isVisible = false;
                        } else if (
                          "nequal" in condition &&
                          data[condition.name] === condition.nequal
                        ) {
                          isVisible = false;
                        }
                      });
                      if (!isVisible) return false;
                    }
                    //Checkar se a opcional está setada
                    else if (options.optional) {
                      if (
                        fieldStatus[name] &&
                        fieldStatus[name].usingOptional === false
                      ) {
                        return false;
                      }
                    }
                    const validData =
                      name in fieldStatus && "valid" in fieldStatus[name]
                        ? fieldStatus[name].valid
                        : undefined;
                    const feedbackMsg =
                      name in fieldStatus ? fieldStatus[name].message : false;

                    switch (type) {
                      case "friendly-url":
                        return (
                          <FormField
                            key={name}
                            ref={(e) => addToRefs(e, index)}
                            name={name}
                            title={title}
                            subtitle={subtitle}
                            hasFeedback
                            tooltip={tooltip}
                            required={validation.required}
                            valid={validData}
                            feedback={feedbackMsg}
                          >
                            <UrlInput
                              name={name}
                              title={title}
                              value={data[name]}
                              options={options}
                              fieldChanged={fieldChanged}
                              friendlyUrlChanged={friendlyUrlChanged}
                              valid={validData}
                              type={
                                validation.password ? "password" : undefined
                              }
                            />
                          </FormField>
                        );
                      case "text":
                        return (
                          <FormField
                            key={name}
                            ref={(e) => addToRefs(e, index)}
                            name={name}
                            title={title}
                            subtitle={subtitle}
                            hasFeedback
                            tooltip={tooltip}
                            required={validation.required}
                            valid={validData}
                            feedback={feedbackMsg}
                          >
                            <TextInput
                              name={name}
                              title={title}
                              value={data[name]}
                              options={options}
                              fieldChanged={fieldChanged}
                              valid={validData}
                              type={
                                validation.password ? "password" : undefined
                              }
                            />
                          </FormField>
                        );
                      case "checkbox":
                        return (
                          <FormField
                            key={name}
                            ref={(e) => addToRefs(e, index)}
                            name={name}
                            title={title}
                            subtitle={subtitle}
                            required={validation.required}
                            hasFeedback
                            tooltip={tooltip}
                            valid={validData}
                            feedback={feedbackMsg}
                          >
                            <CheckboxInput
                              name={name}
                              label={""}
                              subtitle={subtitle}
                              value={data[name]}
                              checkboxChanged={checkboxChanged}
                            />
                          </FormField>
                        );
                      case "file":
                        return (
                          <FormField
                            key={name}
                            ref={(e) => addToRefs(e, index)}
                            name={name}
                            title={title}
                            subtitle={subtitle}
                            tooltip={tooltip}
                            required={validation.required}
                            valid={validData}
                            feedback={feedbackMsg}
                          >
                            <FileInput
                              key={name}
                              name={name}
                              title={title}
                              current={data[name]}
                              fileChanged={fileChanged}
                            />
                          </FormField>
                        );
                      case "select":
                        return (
                          <FormField
                            key={name}
                            ref={(e) => addToRefs(e, index)}
                            name={name}
                            title={title}
                            subtitle={subtitle}
                            required={validation.required}
                            hasFeedback
                            tooltip={tooltip}
                            valid={validData}
                            feedback={feedbackMsg}
                          >
                            <Select
                              name={name}
                              selectOptions={selectOptions}
                              options={options}
                              value={data[name]}
                              defaultVal={
                                translations.select + " " + title + "..."
                              }
                              fieldChanged={fieldChanged}
                              valid={validData}
                            />
                          </FormField>
                        );
                      case "multi":
                        return (
                          <FormField
                            key={name}
                            ref={(e) => addToRefs(e, index)}
                            name={name}
                            title={title}
                            subtitle={subtitle}
                            required={validation.required}
                            hasFeedback
                            tooltip={tooltip}
                            valid={validData}
                            feedback={feedbackMsg}
                          >
                            <MultiSelect
                              key={name}
                              name={name}
                              options={options}
                              selectOptions={selectOptions}
                              value={
                                data[name]
                                  ? selectOptions.filter((option) =>
                                      data[name].includes(option.value)
                                    )
                                  : []
                              }
                              valid={validData}
                              multiChanged={multiChanged}
                            />
                          </FormField>
                        );
                      case "dragdrop":
                        return (
                          <FormField
                            key={name}
                            ref={(e) => addToRefs(e, index)}
                            name={name}
                            title={title}
                            subtitle={subtitle}
                            required={false}
                            tooltip={tooltip}
                            valid={validData}
                            feedback={feedbackMsg}
                          >
                            <DragDrop
                              name={name}
                              fileChanged={fileChanged}
                              id={data._id}
                              value={data[name]}
                              defaultFiles={defaultFiles[name]}
                              defaultChanged={defaultChanged}
                              url={url}
                              options={options}
                              valid={validData}
                            />
                          </FormField>
                        );
                      case "date":
                        return (
                          <FormField
                            key={name}
                            ref={(e) => addToRefs(e, index)}
                            name={name}
                            title={title}
                            subtitle={subtitle}
                            required={validation.required}
                            tooltip={tooltip}
                            valid={validData}
                            feedback={feedbackMsg}
                          >
                            <Date
                              key={name}
                              name={name}
                              value={data[name]}
                              options={options}
                              fieldChanged={fieldChanged}
                            />
                          </FormField>
                        );
                      default:
                        return false;
                    }
                  }
                )}
                <Row className="justify-content-center">
                  <Col md="4">
                    {
                      //Pega os nomes do objeto "optionals" e itera, se algum dos optionals não estiverem
                      //setados, visualiza o botão de adicionar opcionais
                      Object.keys(fieldStatus).filter(
                        (item) =>
                          fieldStatus[item].optional &&
                          !fieldStatus[item].usingOptional
                      ).length > 0 && (
                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className="add_widget"
                          >
                            {translations.add_field}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {Object.keys(fieldStatus)
                              .filter(
                                (item) =>
                                  fieldStatus[item].optional &&
                                  !fieldStatus[item].usingOptional
                              )
                              .map((item) => {
                                return (
                                  <Dropdown.Item
                                    key={item}
                                    onClick={() => handleAddWidget(item)}
                                  >
                                    {fieldStatus[item].title}
                                  </Dropdown.Item>
                                );
                              })}
                          </Dropdown.Menu>
                        </Dropdown>
                      )
                    }
                  </Col>
                </Row>
                <Row className="confirm">
                  {process.env.NODE_ENV === "development" && (
                    <Col xs="auto">
                      <Button
                        className="confirm__button"
                        onClick={() => console.log(data)}
                      >
                        Read state
                      </Button>
                      <Button
                        className="confirm__button"
                        onClick={() => console.log(fieldRefs.current)}
                      >
                        Read refs
                      </Button>
                      <Button
                        className="confirm__button"
                        onClick={() => console.log(fieldStatus)}
                      >
                        Read status
                      </Button>
                      <Button
                        className="confirm__button"
                        onClick={() => console.log(file)}
                      >
                        Read files
                      </Button>
                      <Button
                        className="confirm__button"
                        onClick={() => console.log(defaultFiles)}
                      >
                        Read default files
                      </Button>
                    </Col>
                  )}
                  {loading && (
                    <Col
                      xs="auto"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div
                        style={{
                          width: "40px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <ReactLoading color="#0586D7" type="spin" />
                      </div>
                    </Col>
                  )}
                  <Col xs="auto">
                    <Button className="confirm__button" type="submit">
                      {edit ? translations.edit : translations.add}
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Link to={"/" + url}>
                      <Button className="confirm__button" variant="secondary">
                        {translations.cancel}
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Form>
            )
          }
        </BigCard>
      </Container>
      <Modal
        show={show}
        handleClose={handleClose}
        type={errorMsg !== "" ? "warning" : "error"}
        title={errorMsg !== "" ? translations.warning : translations.error}
        body={errorMsg !== "" ? errorMsg : translations.error_saving}
      />
    </React.Fragment>
  );
};

export default EditPage;
