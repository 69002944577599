//Arquivo para configurar todas as rotas de request do módulo

//Importa a biblioteca Axios com um interceptor customizado
import axios from './client'
import { serialize } from '@equipedigitalfloripa/ed-cms/api'

//Url base do módulo no back-end
const url = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : 'http://localhost:4000/'
const full_url = url + 'imagens/'

//AVISO IMPORTANTE!
//É necessário incluir os credentials em cada request para que o express possa
//adicionar os cookies necessários para uso 
const options = {
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
}

//Configuração adicional para upload de arquivos
const optionsUpload = {
  withCredentials: false
}

//Faz o upload de imagem (única)
const uploadImagem = (id, formData) => {
  axios.post(full_url + 'put', formData, optionsUpload).then(() => {
    alert('Arquivo enviado!')
  })

}


//Pega imagem de imagem
const fetchItem = (id) => axios.get(full_url + 'whats', options)
const fetchPopup = (id) => axios.get(full_url + 'pop-up', options)
const addFake = (formData) => axios.post(full_url + 'fake', formData, options)

const togglePopup = () => axios.get(full_url + 'pop-up/toggle', options);
const fetchInfoPopup = () => axios.get(full_url + 'pop-up/info', options);


export {
  fetchItem,
  fetchPopup,
  uploadImagem,
  addFake,
  togglePopup,
  fetchInfoPopup
}