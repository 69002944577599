import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EditPage } from "@equipedigitalfloripa/ed-cms/components";
//import { fetchCasino, editCasino, uploadCasino } from '../api/casinos'
import * as conveniosApi from "../api/convenio";
import { fetchItems } from "../api/convenio_categoria";

const EditConvenio = ({ edit }) => {
  const [categorias, setCategorias] = useState([]);

  useEffect(() => {
    fetchItems({ status: "A" }).then((res) => {
      let categoriasOptions = [];
      res.data.forEach((categoria) => {
        categoriasOptions.push({
          value: categoria.title,
          label: categoria.title,
        });
      });
      setCategorias(categoriasOptions);
    });
  }, []);

  const translations = useSelector(
    (state) => state.translations.translations["system-convenios"]
  );
  const selectedData = [
    {
      name: "_id",
      type: "hidden",
      title: "Id",
    },
    {
      name: "title",
      type: "text",
      title: translations["edit-title"],
    },
    {
      name: "categoria",
      type: "select",
      title: translations["edit-category"] ?? "Categoria",
      selectOptions: categorias,
      options: {
        populated: true,
      },
    },
    {
      name: "content",
      type: "tiny",
      title: translations["edit-content"],
    },
    
    {
      name: "img",
      type: "dragdrop",
      title: "Logo do convênio",
      options: {
        maxFiles: 1,
      },
    },
    {
      name: "phone",
      type: "text",
      title: translations["edit-phone"],
    },
    {
      name: "email",
      type: "text",
      title: translations["edit-email"],
    },
  ];
  return (
    // <EditPage selectedData={selectedData} apiFunctions={casinoApi} fetchFunction={fetchCasino} editFunction={editCasino} uploadFunction={uploadCasino} edit={true} url='casinos'/>
    <EditPage
      title={translations["editpage-title"]}
      subtitle={translations["editpage-subtitle"]}
      selectedData={selectedData}
      addFunction={conveniosApi.addItem}
      fetchFunction={conveniosApi.fetchItem}
      editFunction={conveniosApi.editItem}
      uploadFunction={conveniosApi.uploadConvenio}
      edit={edit}
      url="convenios"
    />
  );
};

export default EditConvenio;
