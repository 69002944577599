import React from 'react'
import { Container } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import { MdArrowBack } from 'react-icons/md'
import { useSelector } from 'react-redux'
import "./header.scss"

export const Header = ({ title, subtitle, children, back = false }) => {
  const history = useHistory()
  const { system } = useSelector(state => state.translations.translations)


  const handleBack = (e) => {
    e.preventDefault()
    back ? history.push(back) : history.goBack()
  }
  return (
    <div id="header">
      <Container className="page_header">
        <div className="page_header__back">
          <button onClick={handleBack}>
            <MdArrowBack size="2em" />
            <span>{system.back}</span>
          </button>
        </div>
        <div className={"page_header__title"}>
          <h1>{title}{children && <div className="page_header__icon">{children}</div>}</h1>
          <p>{subtitle}</p>
        </div>
      </Container>
    </div>
  )
}


