import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { BigCard, Header } from "@equipedigitalfloripa/ed-cms/components";
import Tabela from "../components/Tabela/Tabela";
import * as noticiasApi from "../api/noticias";
import { fetchItems } from "../api/categorias";
import { useSelector } from "react-redux";

const Noticias = () => {
  const translations = useSelector((state) => state.translations.translations);
  const noticiasTranslations = translations["system-noticias"];
  const [categorias, setCategorias] = useState({});

  useEffect(() => {
    fetchItems().then((res) => {
      let categoriasOptions = {};
      res.data.forEach((categoria) => {
        categoriasOptions[categoria._id] = categoria.nome;
      });
      setCategorias(categoriasOptions);
    });
  }, []);

  const columns = [
    {
      title: noticiasTranslations["manage-image"],
      field: "_id",
      filtering: false,
      sorting: false,
      cellStyle: {
        maxWidth: 66,
      },
      render: (rowData) => (
        <img
          className="noticia-img"
          src={
            rowData.categoria !== undefined
              ? rowData.categoria._id !== "60b7b815cb748f4d8447b8b1"
                ? noticiasApi.getImgNoticia(rowData._id)
                : rowData.galeria && rowData.galeria.length > 0
                ? noticiasApi.getImgGaleria(rowData._id, rowData.galeria[0]._id)
                : ""
              : ""
          }
          alt=""
        />
      ),
    },
    {
      title: noticiasTranslations["manage-title"],
      field: "titulo",
      sorting: true,
    },
    {
      title: noticiasTranslations["manage-category"],
      field: "categoria",
      center: true,
      sorting: categorias,
      render: (rowData) =>
        rowData.categoria !== undefined ? rowData.categoria.nome : "",
    },
  ];

  // const options = {
  //   filtering: true,
  //   actionsColumnIndex: -1,
  //   paging: false
  // }

  return (
    <React.Fragment>
      <Header
        title={noticiasTranslations["title"]}
        subtitle={noticiasTranslations["subtitle"]}
      >
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            url={"noticias"}
            columns={columns}
            fetchFunction={noticiasApi.fetchItems}
            removeFunction={noticiasApi.removeItem}
            statusFunction={noticiasApi.statusItem}
            enableSearch
            enableDatas
          />
        </BigCard>
      </Container>
    </React.Fragment>
  );
};

export default Noticias;
