import React from 'react'

import { Form } from 'react-bootstrap'

const FileInput = ({ name, valid, current = false, fileChanged }) => {
  return (
    <Form.File
      id={name}
      onChange={(e) => fileChanged(e, name)}
      isInvalid={true}
      isValid={false}
      label={current ? current : 'no file'}
    />
  )
}

export default FileInput
