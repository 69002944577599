import React from "react";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { BigCard, Header } from "@equipedigitalfloripa/ed-cms/components";
import { Tabela } from "../components/Tabela/Tabela";
import * as memorialApi from "../api/memorial";

const Memorial = () => {
  async function teste() {
    console.log(await memorialApi.fetchItems({ getTotalItems: true }))
  }

  const columns = [
    {
      title: "Imagem Memorial",
      field: "_id",
      filtering: false,
      sorting: false,
      cellStyle: {
        maxWidth: 36,
      },
      render: (rowData) => (
        <img
          className="noticia-img"
          src={memorialApi.getImgMemorial(rowData._id)}
          alt=""
        />
      ),
    },
    {
      title: "Ordem",
      field: "order",
      sorting: true,
    },
  ];

  return (
    <React.Fragment>
      <Header title={'Memorial'} subtitle={''}>
        <i className="far fa-file-alt awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={memorialApi.fetchItems}
            removeFunction={memorialApi.removeItem}
            statusFunction={memorialApi.statusItem}
            title={'Memorial'}
            url={"memorial"}
          />
        </BigCard>
      </Container>
    </React.Fragment>
  );
};

export default Memorial;
