import React from "react";
import EditPage from "../components/EditPage/EditPage.js";
import * as servicosApi from "../api/servicos_conf";
import { useSelector } from "react-redux";

const EditServicosConf = ({ edit }) => {
  const translations = useSelector((state) => state.translations.translations);
  const servicosTranslations = translations["system-servicos"];

  const selectedData = [
    {
      name: "_id",
      type: "hidden",
      title: "Id",
    },
    {
      name: "a_cadastral",
      type: "text",
      title: "Link Atualizacao Cadastral",
    },
    {
      name: "forum",
      type: "text",
      title: "Link Forum",
    },
    {
      name: "imagem_1",
      type: "dragdrop",
      title: "Ações em andamento ",
      options: {
        maxFiles: 1,
        optional: true,
      },
      validation: {
        required: false,
      },
    },
    {
      name: "imagem_2",
      type: "dragdrop",
      title: "Encontrar os Advogados",
      options: {
        maxFiles: 1,
        optional: true,
      },
      validation: {
        required: false,
      },
    },
    {
      name: "imagem_3",
      type: "dragdrop",
      title: "Ações Finalizadas",
      options: {
        maxFiles: 1,
        optional: true,
      },
      validation: {
        required: false,
      },
    },
  ];
  return (
    <EditPage
      title={
        true
          ? servicosTranslations["editpage-title"]
          : servicosTranslations["addpage-title"]
      }
      subtitle={
        true
          ? servicosTranslations["editpage-subtitle"]
          : servicosTranslations["addpage-subtitle"]
      }
      selectedData={selectedData}
      fetchFunction={servicosApi.fetchItem}
      editFunction={servicosApi.editItem}
      uploadFunction={servicosApi.uploadServicos}
      edit={true}
      url="servicos_conf"
    />
  );
};

export default EditServicosConf;
