import React from 'react'
import { Form } from 'react-bootstrap'

const TextInput = ({ name, title, value, options = {}, fieldChanged, valid, type = 'text', required = false }) => {
  return (
    <Form.Control
      type={type}
      placeholder={title}
      value={value ? value : ''}
      onChange={e => fieldChanged(name, e.target.value, options.maxLength || null)}
      isInvalid={valid === undefined ? false : !valid}
      isValid={valid === undefined ? false : valid}
      autoComplete="one-time-code"
      required
    />
  )
}

export default TextInput
