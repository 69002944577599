import React, { useEffect, useState } from "react";
import { EditPage } from "@equipedigitalfloripa/ed-cms/components";
//import { EditPage } from '../components/EditPage'
import * as juridicosApi from "../api/juridicos";
import { fetchItems } from "../api/categoriasJuridico";
import { useSelector } from "react-redux";

const EditJuridico = ({ edit }) => {
  const translations = useSelector((state) => state.translations.translations);
  const juridicosTranslations = translations["system-juridicos"];

  const [categorias, setCategorias] = useState([]);

  useEffect(() => {
    fetchItems().then((res) => {
      let categoriasOptions = [];
      res.data.forEach((categoria) => {
        categoriasOptions.push({ value: categoria._id, label: categoria.nome });
      });
      setCategorias(categoriasOptions);
    });
  }, []);

  const selectedData = [
    {
      name: "_id",
      type: "hidden",
      title: "Id",
    },
    {
      name: "titulo",
      type: "text",
      title: juridicosTranslations["edit-title"],
      validation: {
        required: true,
      },
      options: {
        maxLength: "150",
      },
      tooltip: {
        text: juridicosTranslations["edit-title-p"],
      },
    },
    {
      name: "categoria",
      type: "select",
      title: juridicosTranslations["edit-categoria"],
      selectOptions: categorias,
      options: {
        populated: true,
      },
    },
    {
      name: "corpo",
      type: "tiny",
      title: juridicosTranslations["edit-corpo"],
    },
  ];
  return (
    <EditPage
      title={
        edit
          ? juridicosTranslations["editpage-title"]
          : juridicosTranslations["addpage-title"]
      }
      subtitle={
        edit
          ? juridicosTranslations["editpage-subtitle"]
          : juridicosTranslations["addpage-subtitle"]
      }
      selectedData={selectedData}
      addFunction={juridicosApi.addItem}
      fetchFunction={juridicosApi.fetchItem}
      editFunction={juridicosApi.editItem}
      uploadFunction={juridicosApi.uploadJuridico}
      edit={edit}
      url="juridicos"
    />
  );
};

export default EditJuridico;
