import React from 'react'
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import * as categoriasJuridicoApi from '../api/categoriasJuridico'
import { useSelector } from 'react-redux'

const CategoriasJuridico = () => {
  const translations = useSelector(state => state.translations.translations)
  const categoriasJuridicoTranslations = translations['system-categoriasJuridico']

  const columns = [
    {
      title: categoriasJuridicoTranslations['manage-title'],
      field: "nome",
    },
  ];

  return (
    <React.Fragment>
      <Header title={categoriasJuridicoTranslations['title']} subtitle={categoriasJuridicoTranslations['subtitle']}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={categoriasJuridicoApi.fetchItems}
            removeFunction={categoriasJuridicoApi.removeItem}
            statusFunction={categoriasJuridicoApi.statusItem}
            title={categoriasJuridicoTranslations['title']}
            url={'categorias_Juridico'}
          />
        </BigCard>

      </Container>
    </React.Fragment>
  )
}

export default CategoriasJuridico
